import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate  } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [isMobileOpen, setIsMobileOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const sidebarRef = useRef(null);
    const navigate = useNavigate();
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleClickOutside2 = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setIsMobileOpen(false); 
        }
    };

    useEffect(() => {
        if (isMobileOpen) {   
          document.addEventListener('mousedown', handleClickOutside2);
        } else {
          document.removeEventListener('mousedown', handleClickOutside2);
        }
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside2);
        };
      }, [isMobileOpen]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <nav className="fixed navbar h-[65px] w-full z-50 px-4 md:px-12 items-center">
                <div className='items-center flex space-x-12'>
                    <img src="/logo.png" alt="Logo" className="w-auto h-[20px]  cursor-pointer" onClick={() => navigate('/')}  />    
                    <div className='flex hidden md:block'>
                        <ul className="flex flex-row space-x-4 md:space-x-6 text-white text-[16px] w-full">
                            <li><NavLink to="/about">A Embed</NavLink></li>
                            <li><NavLink to="/comunidade">Comunidade</NavLink></li>
                            <li className="relative" ref={dropdownRef}>
                                <span
                                    className="cursor-pointer hover:text-[#66cc66]"
                                    onClick={() => setIsOpen(!isOpen)}
                                >
                                    Soluções
                                </span>
                            </li>
                            <li className=''>
                                <span  onClick={() => window.open('https://github.com/embed-labs', '_blank')} className='cursor-pointer text-white hover:text-[#66cc66]'>Developers</span>
                            </li>
                        </ul>
                    </div>
                </div>
                {isOpen && (
                    <div className="absolute left-0 top-full w-full bg-[#000000] shadow-lg p-6 hidden md:block border-t-[1px] border-[#66cc66]" ref={dropdownRef}>
                        <div className="grid grid-cols-3 gap-x-12 px-8">
                            <div>
                                <div className=''>
                                   <p className='font-bold text-white'>Pagamentos</p> 
                                   <hr className='text-white mt-2'/>
                                </div>
                                <ul className='text-white mt-4 space-y-2'>
                                    <li><NavLink to="/subadquirencia-whitelabel" onClick={() => setIsOpen(!isOpen)} >Subadquirência Whitelabel</NavLink></li>
                                    <li><NavLink to="/pix" onClick={() => setIsOpen(!isOpen)}>PIX</NavLink></li>
                                    <li><NavLink to="/pos" onClick={() => setIsOpen(!isOpen)} >POS-Connect</NavLink></li>
                                    <li><NavLink to="/tef" onClick={() => setIsOpen(!isOpen)}>TEF</NavLink></li>
                                </ul>
                            </div>
                            <div>
                                <div className=''>
                                   <p className='font-bold text-white'>Inteligência Artificial</p> 
                                   <hr className='text-white mt-2'/>
                                </div>
                                <ul className='text-white mt-4 space-y-2'>
                                    <li><NavLink to="/iaas" onClick={() => setIsOpen(!isOpen)}>AI as a Service</NavLink></li>
                                </ul>
                            </div>
                            <div>
                                <div className=''>
                                   <p className='font-bold text-white'>Financeiro</p> 
                                   <hr className='text-white mt-2'/>
                                </div>
                                <ul className='text-white mt-4 space-y-2'>
                                    <li><NavLink to="/antecipacao-recebiveis" onClick={() => setIsOpen(!isOpen)}>Antecipação de Recebíveis</NavLink></li>
                                    <li><NavLink to="/xml" onClick={() => setIsOpen(!isOpen)} >Armazenamento Fiscal (XML)</NavLink></li>
                                    <li><NavLink to="/servico-financeiro" onClick={() => setIsOpen(!isOpen)} >Serviços Financeiros</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
                <div className='flex hidden md:block'>
                    <ul className="flex flex-row space-x-4 md:space-x-6 text-white text-[16px] w-full md:w-auto">
                        <li><p className='text-white hover:text-[#66cc66] cursor-pointer' onClick={() => window.open('https://wa.me/5541997597962', '_blank')}>Falar com vendas</p></li>
                        <li className=''>
                            <p className='text-white hover:text-[#66cc66] cursor-pointer' onClick={() => window.open('https://admin-hml.embed.it/', '_blank')}>Login</p>
                        </li>
                    </ul>
                </div>
                <div className='cursor-pointer block md:hidden' onClick={() => setIsMobileOpen(true)} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none">
                        <rect width="30" height="5" rx="2.5" fill="white"/>
                        <rect y="12" width="30" height="5" rx="2.5" fill="white"/>
                        <rect y="24" width="30" height="5" rx="2.5" fill="white"/>
                    </svg>
                </div>
            </nav>
            <div ref={sidebarRef} className={`md:hidden fixed top-0 right-0 z-50 flex-1 w-1/2 h-screen bg-[#000000] transition-transform duration-300 overflow-hidden ${isMobileOpen ? 'translate-x-0 ' : 'translate-x-full'}`}>
                <button onClick={() => setIsMobileOpen(false)} className="absolute top-4 left-4 text-white font-black text-[30px] ">X</button>
                <div className="h-full py-4 overflow-y-auto mt-6">
                    <div className="h-full p-4 overflow-y-auto mt-6 justify-center text-center text-[20px]">
                        <ul className={`flex flex-col space-y-8 font-medium`}>
                            <li className=''>
                                <Link to={'/about'} className="inline-flex py-2 text-white hover:text-[#66cc66]" onClick={() => setIsMobileOpen(false)}>
                                    <span>A Embed</span>
                                </Link>
                            </li>
                            <li className=''>
                                <Link to={'/comunidade'} className="inline-flex py-2 text-white hover:text-[#66cc66]" onClick={() => setIsMobileOpen(false)}>
                                    <span>Comunidade</span>
                                </Link>
                            </li>
                            <li className=''>
                                <div className='text-white space-y-2'>
                                    <p>Soluções</p>
                                    <div className='ml-2 space-y-1'>
                                        <div>
                                            <Link to={'/tef'} className="text-white hover:text-[#66cc66]" onClick={() => setIsMobileOpen(false)}>
                                                <p>• TEF</p>
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={'/xml'} className="text-white hover:text-[#66cc66]" onClick={() => setIsMobileOpen(false)}> 
                                                <p>• XML</p>
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={'/pos'} className="text-white hover:text-[#66cc66]" onClick={() => setIsMobileOpen(false)}>
                                                <p>• POS</p>
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={'/pix'} className="text-white hover:text-[#66cc66]" onClick={() => setIsMobileOpen(false)}>
                                                <p>• PIX</p>
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={'/iaas'} className="text-white hover:text-[#66cc66]" onClick={() => setIsMobileOpen(false)}>
                                                <p>• IAaS</p>
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={'/antecipacao-recebiveis'} className="text-white hover:text-[#66cc66]" onClick={() => setIsMobileOpen(false)}>
                                                <p>• AR</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className=''>
                                <span  onClick={() => window.open('https://github.com/embed-labs', '_blank')} className='cursor-pointer text-white hover:text-[#66cc66]'>Developers</span>
                            </li>
                            <li className=''>
                            <span className='text-white hover:text-[#66cc66] cursor-pointer' onClick={() => window.open('https://wa.me/5541997597962', '_blank')}>Falar com vendas</span>
                            </li>
                            <li className=''>
                                <span className='text-white hover:text-[#66cc66]' onClick={() => window.open('https://admin-hml.embed.it/', '_blank')}>Login</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>

    );
};

export default Navbar;
