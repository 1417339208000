import React, { useState }  from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Home.css';
import { motion } from "framer-motion";

const Home = () => {

    {/*useEffect(() => {
        const carousel = document.querySelector(".testimonials-carousel");
        const prevBtn = document.querySelector(".prev-btn");
        const nextBtn = document.querySelector(".next-btn");

        let scrollAmount = 0;
        const scrollStep = carousel.offsetWidth / 2;

        prevBtn.addEventListener("click", () => {
            carousel.scrollBy({
                left: -scrollStep,
                behavior: "smooth",
            });
            scrollAmount = Math.max(0, scrollAmount - scrollStep);
        });

        nextBtn.addEventListener("click", () => {
            carousel.scrollBy({
                left: scrollStep,
                behavior: "smooth",
            });
            scrollAmount = Math.min(
                carousel.scrollWidth - carousel.clientWidth,
                scrollAmount + scrollStep
            );
        });

        return () => {
            prevBtn.removeEventListener("click", () => { });
            nextBtn.removeEventListener("click", () => { });
        };
    }, []);*/}

    const [activeIndex, setActiveIndex] = useState(0);

    const features = [
        {
            title: 'Hub de Pagamentos (TEF HUB)',
            description: 'Facilite a conexão dos sistemas dos seus clientes aos principais TEFs do mercado por meio de uma integração única e eficiente.',
            icon: 'home/icone-tef.svg',
            link: 'tef',
            status: true
        },
        {
            title: 'Smart POS Android Hub',
            description: 'Permita que seus clientes integrem os principais terminais POS do mercado ao sistema deles com apenas uma integração simples e eficaz.',
            icon: 'home/icone-pos.svg',
            link: 'pos',
            status: true
        },
        {
            title: 'Armazenamento de NFs',
            description: 'Ofereça aos seus clientes uma solução segura e confiável para armazenar documentos fiscais, atendendo às exigências regulatórias com tranquilidade.',
            icon: 'home/icone-xml.svg',
            link: 'xml',
            status: true
        },
        {
            title: 'PIX',
            description: 'Ofereça aos seus clientes transações instantâneas via Pix para todos os bancos, com suporte integrado para split de pagamentos.',
            icon: 'home/icone-pix.svg',
            link: 'pix',
            status: true
        },
        {
            title: 'AI as a Service',
            description: 'Proporcione aos seus clientes uma IA personalizada com a identidade da sua marca, permitindo que o varejista utilize a solução com seus próprios dados.',
            icon: 'home/icone-iaas.svg',
            link: 'iaas',
            status: true
        },
        {
            title: 'Antecipação de Recebíveis',
            description: 'Garanta mais previsibilidade financeira ao antecipar seus recebíveis com segurança e agilidade, contando com suporte integrado para gestão eficiente.',
            icon: 'home/icone-ar.svg', 
            link: 'antecipacao-recebiveis',
            status: true
        },
        {
            title: 'Serviços Financeiros',
            description: 'Transforme sua solução em um canal para oferecer serviços financeiros, como empréstimos, antecipação de recebíveis, capital de giro e pagamento de conta.',
            icon: 'home/icone-servicofinanceiro.svg',
            link: 'servico-financeiro',
            status: true
        },
        {
            title: 'Subadquirência Whitelabel',
            description: 'Seu cliente pode receber pagamentos em maquininhas personalizadas, reforçando sua identidade visual para o lojista. Além de gerar mais receita para sua empresa.',
            icon: 'home/icone-subwhitelabel.svg',
            link: 'subadquirencia-whitelabel',
            status: true
        },
        ///////////////
        
        /*{
            title: 'Retail Stars',
            description: 'Ajude seus clientes a coletar feedback direto no ponto de venda, melhorando a experiência do consumidor e promovendo maior fidelização.',
            icon: 'home/icone-retailstart.svg',
            link: '',
            status: false
        },
        {
            title: 'Link de pagamento (Lik Wallet)',
            description: 'Habilite seus clientes a oferecerem pagamentos por Apple Pay e Google Pay diretamente no celular, aumentando a conveniência para consumidores e varejistas.',
            icon: 'home/icone-linkwallet.svg',
            link: '',
            status: false
        },
        {
            title: 'Bitcoin & USDT',
            description: 'Permita que seus clientes ofereçam pagamentos em Bitcoin e USDT, diversificando as opções de pagamento e protegendo contra a desvalorização do Real.',
            icon: 'home/icone-usdt.svg',
            link: '',
            status: false
        },*/
    ];

    const partners = [
        { src: "home/microsoft.svg", alt: "Microsoft", class:"h-[30px]" },
        { src: "home/daten.png", alt: "daten", class:"h-[80px]" },
        { src: "home/visa.svg", alt: "Visa", class:"h-[90px]" },
        { src: "home/google.svg", alt: "Google", class:"h-[40px]" },
        { src: "home/gs1.png", alt: "gs1", class:"h-[60px]" },
        { src: "home/getNet.png", alt: "GetNet", class:"h-[30px]" },
        { src: "home/afrac.png", alt: "afrac", class:"h-[45px]" },
        { src: "home/mastercard.svg", alt: "MasterCard", class:"h-[70px]" },
        { src: "home/entrepay.svg", alt: "entrepay", class:"h-[40px]" },
        { src: "home/extcapital.png", alt: "extcapital", class:"h-[55px]" },
        { src: "home/cielo.svg", alt: "cielo", class:"h-[40px]" },
        { src: "home/rede.png", alt: "rede", class:"h-[65px]" },
        { src: "home/safrapay.png", alt: "safrapay", class:"h-[60px]" },
        { src: "home/stone.svg", alt: "stone", class:"h-[30px]" },
        { src: "home/pagbank.png", alt: "pagbank", class:"h-[40px]" },
        { src: "home/aws.png", alt: "aws", class:"h-[45px]" },
        { src: "home/databricks.png", alt: "databricks", class:"h-[60px]" },
        
      ];

    return (
        <div className="home">
            {/* Seção Hero com Vídeo de Fundo */}
            <section className="hero">
                <video autoPlay loop muted playsInline className="background-video">
                    <source src="/home/background.mp4" type="video/mp4" />
                    Seu navegador não suporta vídeos.
                </video>
                <div className="hero-text">
                    <h1><span className="highlight">Reinventando</span> a forma como </h1>
                    <h1><span className="highlight">software houses</span> e <span className="highlight">automações comerciais</span></h1>
                    <h1>operam e crescem seus negócios, prosperam</h1>
                    <h1>e expandem seus horizontes</h1>
                </div>
            </section>
            {
                
            }

            {/* Seção Sobre a Embed */}
            <section className="about">
                <div className="about-content">

                    <div className="about-content-text mt-6 text-[25px] md:text-[30px]">
                        <h2>Soluções agnósticas para <span style={{fontWeight:'bold', marginLeft:5}}>comprar, vender, pagar </span> <img style={{marginLeft:10}} src="home/logo2.png" alt="Sobre a Embed" className="about-image" /><span style={{fontWeight:'bold', marginLeft:5}}>receber</span> </h2>
                    </div>
                    <p  className= 'text-justify' style={{fontWeight:'inherit', marginBottom:'30px'}}>
                        Uma comunidade feita para software houses! Somos uma plataforma dedicada a capacitar software houses na <span style={{fontWeight:'bolder'}}>distribuição de soluções e serviços financeiros para o varejo</span>. Faça parte de uma rede que impulsiona <span style={{fontWeight:'bolder'}}>inovação, crescimento e sucesso para o seu negócio</span>.    
                    </p>
                </div>
            </section>

            {/* Nova Seção - Conheça Nossas Soluções */}
            <section className="about-solutions">
                <div className="solutions-content">
                    <div className="solutions-text">
                        <p className="solutions-heading">Nossas Soluções</p>
                        <p style={{fontWeight:'inherit', fontSize:'28px', margin: '0'}}>Negócios operam e crescem</p>
                        <p style={{fontSize:'28px', margin: '0', marginBottom: '40px'}}><span style={{fontWeight:'bolder'}}>com uma única integração</span></p>
                        <div className='text-justify md:text-center' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <p style={{maxWidth:'1100px', lineHeight: '1.5', fontSize:'18px', marginBottom:'50px'}}>
                                Com APIs fáceis de integrar e dados conectados, potencializamos o crescimento do seu negócio e dos negócios dos seus clientes 
                                através do nosso ecossistema. <span style={{fontWeight:'bolder'}}>Para qualquer tipo de pagamento e fluxo de dados, em qualquer canal, em qualquer dispositivo, 
                                conte com a Embed para transformar o varejo</span>.
                            </p>
                        </div>
                        <div className='listImage flex flex-col md:flex-row items-start gap-6'>
                            <ul className="solutions-list flex-1 pl-6">
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Antecipação de recebíveis</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">POS Multi-adquirente</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Motores de pagamento (TEFs)</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Pix e Wallets</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Armazenamento de NFs</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Conexão com Smart POS Android</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Pagamento de contas no PDV</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Conta digital</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Loyalty</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">IA personalizada</li>
                                <li className="relative pl-6 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-3 before:h-3 before:bg-green-400 before:rounded-full">Empréstimos para o Varejo</li>
                            </ul>
                            <div className="solutions-image flex-1 flex justify-center md:justify-end">
                                <img src="home/person1.png" alt="Imagem descritiva" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="about-dll py-[20px] px-4 md:px-[80px] text-[18px] md:text-[24px]" >
                <div className='text1'>
                    <p><span>Code once_</span></p>
                    <p>Uma vez Embed,</p>
                    <p>Embedado está.</p>
                </div>
                <div className='text2'>
                    <p>/um único idioma</p>
                    <p>/uma única integração</p>
                    <p>/um único protocolo</p>
                </div>
                <div className='text3'>
                    <p>/sem DLL Hell</p>
                    <p>/sem API Hell</p>
                    <p className='text-white'>_muito mais fácil.</p>
                </div>
            </section>

            {/* Seção Soluções */}
            <section className="solutions">
                <h2>Soluções em destaque</h2>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-2 items-center'>
                    {features.map((feature, index) => (
                        <div className={`p-8 shadow-md text-start  h-[230px] ${feature.status ? 'bg-white hover:bg-[#F0F0F0] cursor-pointer' : 'bg-[#F0F0F0] ' }`} key={index}>
                            <Link to={`/${feature.link}`} className='block cursor-default'>
                                <div className="text-2xl mb-[10px]"><img src={feature.icon} className='w-8 h-8'/></div>
                                <h3 className="text-[14px] font-semibold mb-[5px]">{feature.title}</h3>
                                <p className="text-gray-600 text-[13px]">{feature.description}</p>
                            </Link>
                        </div>
                    ))}
                </div>
            </section>


            {/* Seção Empresas Parceiras */}
            <section className="bg-[#1c1c1c] py-[40px] text-center overflow-hidden">
                <p className="mb-4 text-white">EMPRESAS PARCEIRAS</p>
                <h4 className="text-[#80FF80] text-[1.4rem] md:text-[1.7rem] mb-[20px] px-3 md:px-0 ">
                    Quem evolui o ecossistema com a Embed
                </h4>
                <div className="w-screen overflow-hidden h-[150px] flex items-center">
                    <motion.div
                    className="flex w-max gap-x-20 items-center h-full"
                    animate={{ x: ["0%", "-100%"] }} // Move até a metade e reinicia suavemente
                    transition={{ repeat: Infinity, duration: 30, ease: "linear" }}
                    >
                    {[...partners, ...partners].map((partner, index) => (
                        <img
                        key={index}
                        className={`${partner.class} filter grayscale hover:grayscale-0 transition`}
                        src={partner.src}
                        alt={partner.alt}
                        />
                    ))}
                    </motion.div>
                </div>
            </section>

            {/*
             Seção Depoimentos 
            <section className="testimonials px-4">
                <p className="title">DEPOIMENTOS</p>
                <h4>Com a palavra, os membros da nossa comunidade</h4>
                <div className="testimonials-carousel">
                    <div className="testimonial-card">
                        <blockquote>
                            <p>"A Embed revolucionou nossa forma de operar no mercado!"</p>
                            <p style={{color:'white', margin:0}}>Heloisa Lisboa</p>
                            <p style={{margin:0, fontWeight:'bold'}}>CEO da SWH Curitibana</p>
                        </blockquote>
                    </div>
                    <div className="testimonial-card">
                        <blockquote>
                            <p>"Uma experiência incrível com soluções eficazes!"</p>
                            <p style={{color:'white', margin:0}}>Fellipe Costa</p>
                            <p style={{margin:0, fontWeight:'bold'}}>Diretor da Atma Sistemas</p>
                        </blockquote>
                    </div>
                    <div className="testimonial-card">
                        <blockquote>
                            <p>"O suporte da Embed é impecável, sempre atentos às nossas necessidades! Recomendo"</p>
                            <p style={{color:'white', margin:0}}>Carla Dias</p>
                            <p style={{margin:0, fontWeight:'bold'}}>CTO da TecVendas</p>
                        </blockquote>
                    </div>
                    <div className="testimonial-card">
                        <blockquote>
                            <p>"Facilitou muito nosso processo de integração com o PDV, altamente recomendada!"</p>
                            <p style={{color:'white', margin:0}}>Marcos Ferreira</p>
                            <p style={{margin:0, fontWeight:'bold'}}>Diretor da Solux Sistemas</p>
                        </blockquote>
                    </div>
                </div>
                <div className="carousel-controls">
                    <button className="prev-btn">‹</button>
                    <button className="next-btn">›</button>
                </div>
            </section>
            */}
            <section className="options-section">
                <div className="option-card">
                    <img src="/community-icon.png" alt="Ícone da Comunidade" />
                    <p className='title'><span>Faça parte da
                        comunidade que </span>está
                        mudando o varejo!</p>
                    <p>Preencha o formulário clicando abaixo
                        e entraremos em contato com você
                        em breve para iniciar os trabalhos.</p>
                    <button className='rounded-md' onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSf5VYQzkPWJTbgqIA7Cg787U_5kW4lM5yg5Jfur5f1FLT9n0g/viewform', '_blank')}>Quero ser Embed!</button>
                </div>
                <div className="option-card">
                    <img src="/contact-icon.png" alt="Ícone de Contato" />
                    <p className='title'><span>Entre em contato </span>
                        com a Embed pelo
                        nosso WhatsApp:</p>
                    <p>Nosso time está à disposição no
                        WhatsApp para tirar dúvidas e apoiar você
                        no preenchimento dos primeiros dados.</p>
                    <button className='rounded-md' onClick={() => window.open('https://wa.me/5541997597962', '_blank')}>Fale conosco</button>
                </div>

            </section>
        </div>
    );
};

export default Home;
