import React, { useState } from 'react';
import '../styles/Xml.css'; 
import '../styles/Tef.css'; 

const ServicoFinanceiro = () => {
    return (
        <div className="embedXml">
        <section className="headerTef">
            <img className="background-img" src="/xml/backgroundXml.jpg" alt="Imagem descritiva" />
            <div className='flex w-full h-full justify-center items-center'>
                <div className="flex flex-col md:flex-row w-full md:max-w-[1150px] md:w-[80%] h-full justify-center items-center px-4 md:px-0 gap-x-0 md:gap-x-[40px] gap-y-[80px] md:gap-y-0">
                    <div className="md:flex-1 flex flex-col justify-center items-center text-center">
                        <p className="text-[35px] md:text-[45px] text-white">
                        <span className="highlight">Serviços financeiros</span>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section className="about-tef">
            <div className="flex flex-col px-4 md:px-0 mb-10">
                <div className="tef-text">
                    <div className="flex flex-col md:flex-row justify-center items-center">
                        <div className='flex h-auto w-full md:w-[450px]'>
                            <img src="/xml/person6.png" alt="Imagem descritiva" className='flex w-full h-full' />
                        </div>
                        <div className='ml-0 mt-4 md:mt-0 md:ml-4'>
                        <p>Otimize sua gestão financeira com soluções inteligentes e integradas. Nossa plataforma permite automação de processos, 
                            análise preditiva e tomada de decisão estratégica, garantindo mais eficiência e segurança para suas operações.
                            <span> Como sempre,uma vez Embedado, Embedado está!</span></p>

                            <p className='mt-4'>Mais do que serviços financeiros, entregamos inteligência aplicada para potencializar seus resultados e impulsionar seu crescimento.</p>
                        </div>
                    </div>
                    <div className="flex-col justify-center items-center mt-[40px]">
                        <div className='whitespace-nowrap md:whitespace-normal'>
                            <h2 className='font-normal text-[25px]'>Quer embedar essa solução?</h2>

                        </div>
                        <div className="tef-title-row flex-col md:flex-row">
                            <h2 className='font-bold text-[25px]'>Clique e fale conosco!</h2>
                            <hr className='hidden md:block'/>
                            <button className='about-btn rounded-md mt-4 md:mt-0' onClick={() => window.open('https://wa.me/5541997597962', '_blank')}>Fale conosco</button>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <section className="about-dll py-[20px] px-4 md:px-[80px] text-[18px] md:text-[24px]" >
            <div className='text1'>
                <p><span>Code once_</span></p>
                <p>Uma vez Embed,</p>
                <p>Embedado está.</p>
            </div>
            <div className='text2'>
                <p>/um único idioma</p>
                <p>/uma única integração</p>
                <p>/um único protocolo</p>
            </div>
            <div className='text3'>
                <p>/sem DLL Hell</p>
                <p>/sem API Hell</p>
                <p className='text-white'>_muito mais fácil.</p>
            </div>
        </section>

        {/*<section className="fluxos px-4 py-[30px] md:px-[40px] ">
            <div className="fluxos-list">
                <div className='fluxo-item-xml'>
                    <img src="/xml/xml-fluxo.png" alt="fluxo do xml" />
                </div>

            </div>

        </section>*/}


        {/* Nova Seção com Opções de Contato e Comunidade */}
        <section className="options-section">
            <div className="option-card">
                <img src="/community-icon.png" alt="Ícone da Comunidade" />
                <p className='title'><span>Faça parte da
                    comunidade que </span>está
                    mudando o varejo!</p>
                <p>Preencha o formulário clicando abaixo
                    e entraremos em contato com você
                    em breve para iniciar os trabalhos.</p>
                <button className='rounded-md' onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSf5VYQzkPWJTbgqIA7Cg787U_5kW4lM5yg5Jfur5f1FLT9n0g/viewform', '_blank')}>Quero ser Embed!</button>
            </div>
            <div className="option-card">
                <img src="/contact-icon.png" alt="Ícone de Contato" />
                <p className='title'><span>Entre em contato </span>
                    com a Embed pelo
                    nosso WhatsApp:</p>
                <p>Nosso time está à disposição no
                    WhatsApp para tirar dúvidas e apoiar você
                    no preenchimento dos primeiros dados.</p>
                <button className='rounded-md' onClick={() => window.open('https://wa.me/5541997597962', '_blank')}>Fale conosco</button>
            </div>

        </section>
    </div>
);
};

export default ServicoFinanceiro;