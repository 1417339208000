import React, { useState } from 'react';
import '../styles/About.css'; // Importe o CSS para estilizar a página
import { Link } from 'react-router-dom';
const About = () => {
    const [startIndex, setStartIndex] = useState(0); // Estado para controlar o índice inicial
    const totalItems = 9; // Total de imagens
    const itemsPerPage = 5; // Quantidade de itens visíveis por vez

    const handlePrevClick = () => {
        setStartIndex((prevIndex) => Math.max(prevIndex - itemsPerPage, 0)); // Atualiza o índice inicial para retroceder
        console.log('Prev click');
    };

    const handleNextClick = () => {
        setStartIndex((prevIndex) => Math.min(prevIndex + itemsPerPage, totalItems - itemsPerPage)); // Avança o índice
        console.log('Next click');
    };

    const languages = [
        { src: "/about/c++.png", alt: "c++" },
        { src: "/about/delphi.png", alt: "delphi" },
        { src: "/about/python.png", alt: "python" },
        { src: "/about/java.png", alt: "java" },
        { src: "/about/php.png", alt: "php" },
        { src: "/about/csharp.png", alt: "c#" },
        { src: "/about/go.png", alt: "go" },
        { src: "/about/dotnet.png", alt: ".NET" },
        { src: "/about/flutter.png", alt: "flutter" },
    ];

    const features = [
        {
          icon: "",
          text: "Move their businesses forward with",
          highlight: "technology strategy and architecture",
          description: "that supports their ambitions",
        },
        {
          icon: "",
          text: "Maximise operational efficiencies through",
          highlight: "digitalisation and automation",
          description: "of core business functions",
        },
        {
          icon: "",
          text: "Create preference-driving customer experiences with",
          highlight: "engaging mobile apps",
        },
        {
          icon: "",
          text: "Reduce the risk and impact of security threats with",
          highlight: "leading-edge cyber security",
        },
      ];

    return (
        <div className="embed">
            <section className="header">
                <img className="background-img" src="/about/backgroundAbout.jpg" alt="Imagem descritiva" />
                <div className="header-text-1">
                    <div className="header-column">
                        <h2 className='text-[40px] md:text-[50px]'>
                            Bem-vindo ao<br/>
                            <span className="highlight" >futuro da tecnologia</span><br/>
                            no varejo!
                        </h2>
                    </div>
                    <div className="header-column">
                        <p className='text-[20px] md:text-[25px]'>
                            Somos um grupo de inquietos por tecnologia e
                            inovação, focados em levar as melhores
                            soluções para o varejo no Brasil e oferecemos a
                            estrutura de tecnologia que apoia e valoriza o
                            sucesso duradouro do seu negócio!
                        </p>
                    </div>
                </div>
            </section>

            {/* Seção Sobre a Embed */}
            <section className="aboutEmbed mb-8">
                <div className="aboutEmbed-content">
                    <div className="">
                        <img src="/community-green-icon.png" alt="Sobre a Embed" className="icon-image h-12" />
                        <p className='text-justify'>Contamos com <span>mais de 20 anos de experiência
                            em Automação Comercial. </span>
                            Com pessoas, ferramentas e tecnologia, embarcamos serviços
                            financeiros de modo massificado em soluções
                            de PDV.</p>
                        <img src="/about/person3.png" alt="Sobre a Embed" className="person-image1" />

                    </div>
                    <div className="about-content">
                        <img src="/about/person4.png" alt="Sobre a Embed" className="person-image" />
                        <img src="/hand-icon.png" alt="Sobre a Embed" className="icon-image h-12 mt-4 hidden md:block"  />
                        <p className='text-justify'>Não somos apenas uma plataforma, somos uma
                            comunidade de profissionais apaixonados por
                            tecnologia e inovação. Nosso pilar é a confiança
                            em nosso ecossistema e em nossos parceiros.
                            <span> Juntos, vamos transformar a maneira como seu
                                negócio opera e cresce!</span></p>
                        
                    </div>
                    
                </div>
                <div className=''><button className='rounded-md'><Link to={'/comunidade'}>Quero ser Embed!</Link></button></div>
            </section>

            

            {/* Seção linguagens */}
            <section className="flex flex-col px-8 py-9 bg-[#000000] border-b-2 border-[#80FF80]"> 
                <div className='flex w-full justify-center'>
                    <p className='text-white '>LINGUAGEM E SISTEMA OPERACIONAL AGNÓSTICO</p>
                </div>
                <div className='flex py-4 w-full grid grid-cols-1 md:grid-cols-2 gap-y-6 mt-4'>
                    <div>
                        <h2 className='mt-4 text-[17px] text-[#80FF80]'>
                            <span className='font-bold '>Integração completa e suporte</span> para simplificar a<br/>
                            vida dos programadores e da comunidade.
                        </h2>
                    </div>
                    <div className='flex w-full items-center justify-center grid grid-cols-3 gap-y-4 md:grid-cols-6 md:gap-y-2'>
                        <img src="/about/linguagemProgramacao/angular.svg" alt="Angular" className='h-[20px]'/>
                        <img src="/about/linguagemProgramacao/docker.svg" alt="docker" className='h-[40px]'/>
                        <img src="/about/linguagemProgramacao/java.svg" alt="java" className='h-[45px]'/>
                        <img src="/about/linguagemProgramacao/javascript.svg" alt="javascript" className='h-[30px]'/>
                        <img src="/about/linguagemProgramacao/kotlin.svg" alt="kotlin" className='h-[30px]'/>
                        <img src="/about/linguagemProgramacao/node-js.svg" alt="node-js" className='h-[60px]'/>
                        <img src="/about/linguagemProgramacao/react.svg" alt="react" className='h-[30px]'/>
                    </div>
                    <div>
                    <h2 className='mt-8 text-[17px] text-[#80FF80]'>
                        <span className='font-bold'>Não importa o sistema operacional</span>,
                        a Embed tem a solução.<br/>
                    </h2>
                    </div>
                    <div className='mt-2 flex w-full items-center justify-center space-x-4 '>
                        <img src="/about/linguagemProgramacao/windows.svg" alt="windows" className='h-[40px]'/>
                        <img src="/about/linguagemProgramacao/android.svg" alt="Android" className='h-[40px]'/>
                        <img src="/about/linguagemProgramacao/google-chrome.svg" alt="chrome" className='h-[40px]'/>
                        <img src="/about/linguagemProgramacao/apple.svg" alt="apple" className='h-[40px]'/>
                        <img src="/about/linguagemProgramacao/linux.svg" alt="Linux" className='h-[40px]'/>
                        
                        
                    </div>

                </div>
                {/*<p>LINGUAGEM E SISTEMA OPERACIONAL AGNÓSTICO</p>
                <h2 className='mt-4 text-[20px]  md:text-[30px] '><span className='font-bold '>Integração completa e suporte</span> para simplificar a<br/>
                    vida dos programadores e da comunidade</h2>
                <div className="language-list-wrapper mt-16">
                    <div className="language-item" style={{ transform: `translateX(-${startIndex * 120}px)` }}>
                        {languages.map((lang, index) => (
                            <img key={index} src={lang.src} alt={lang.alt} className="language-img" />
                        ))}
                    </div>
                        <img src="/left.png" alt="left" className="left-arrow" onClick={handlePrevClick} />
                        <img src="/right.png" alt="right" className="right-arrow" onClick={handleNextClick} />
                </div>
                <h2 className='mt-24 text-[20px]  md:text-[30px] '><span className='font-bold '>Não importa o sistema operacional,</span><br/>
                    a Embed tem a solução</h2>
                <div className="so-item mb-24">
                    <img src="/about/android.png" alt="android" className="so-img" />
                    <img src="/about/windows.png" alt="windows" className="so-img" />
                    <img src="/about/linux.png" alt="linux" className="so-img" />
                    <img src="/about/ios.png" alt="ios" className="so-img" />
                    <img src="/about/internet.png" alt="internet" className="so-img" />
                </div>*/}
            </section>

            {/* Seção Depoimentos */}
            
            
            {/* Seção Empresas Parceiras */}
            <section className="members">
                <div className="flex flex-col md:flex-row justify-center items-center gap-0 md:gap-[100px] mt-[20px]">
                    <h4 className=''>O Varejo e a Tecnologia estão em nosso sangue! </h4>
                </div>
                <div className="flex flex-col md:flex-row justify-center items-center gap-0 md:gap-[20px] mt-2">
                    <div className='text-center p-[20px] w-[230px]'>
                        <img src="/about/jf.png" alt="member" className='' />
                        <p className='text-[16px] text-[#80FF80] mt-1'>Jonathan Feder</p>
                        <p className='cursor-pointer text-[16px] text-white hover:text-[#80FF80] mt-1' onClick={() => window.open('https://www.linkedin.com/in/jonathansfeder/', '_blank')}>LinkedIn</p>
                    </div>
                    <div className='text-center p-[20px] w-[230px]'>
                        <img src="/about/ca.png" alt="member" />
                        <p className='text-[16px] text-[#80FF80] mt-1'>Claudenir Andrade</p>
                        <p className='cursor-pointer text-[16px] text-white hover:text-[#80FF80] mt-1' onClick={() => window.open('https://www.linkedin.com/in/claudenirandrade/', '_blank')}>LinkedIn</p>
                    </div>
                </div>
                
            </section>

            <section className='bg-[#80FF80] px-[50px] py-[50px] text-center'>
                <p className='font-light'>O JEITO EMBED</p>
                <h2 className='font-medium text-[25px] mt-2 px-4 md:px-0 text-white'>Nossa forma de fazer tecnologia</h2>
                <div className=' flex w-full justify-center'>
                    <div className='grid grid-cols-1 md:grid-cols-6 gap-x-0 gap-y-8 md:gap-x-[30px] md:gap-y-0 mt-8 md:w-full'>
                        <div className='w-[160px]'>
                            <div className='h-9'>
                                <img src="/about/about-tec-icon1.png" alt="Sobre a Embed" className="h-full text-start" />
                            </div>
                            <div>
                                <p className='text-[14px] font-medium text-start mt-3'>
                                    Mind-set de integração e API
                                </p>
                            </div>
                        </div>
                        <div className='w-[160px]'>
                            <div className='h-9'>
                                <img src="/about/about-tec-icon2.png" alt="Sobre a Embed" className="h-full text-start" />
                            </div>
                            <div>
                                <p className='text-[14px] font-medium text-start mt-3'>
                                    Se não for para inovar, nem saimos de casa!
                                </p>
                            </div>
                        </div>
                        <div className='w-[160px]'>
                            <div className='h-9'>
                                <img src="/about/about-tec-icon3.png" alt="Sobre a Embed" className="h-full text-start" />
                            </div>
                            <div>
                                <p className='text-[14px] font-medium text-start mt-3'>
                                    Varejo está em nosso sangue!
                                </p>
                            </div>
                        </div>
                        <div className='w-[160px]'>
                            <div className='h-9'>
                                <img src="/about/about-tec-icon4.png" alt="Sobre a Embed" className="h-full text-start" />
                            </div>
                            <div>
                                <p className='text-[14px] font-medium text-start mt-3'>
                                    Apaixonados por desenvolvedores!
                                </p>
                            </div>
                        </div>
                        <div className='w-[160px]'>
                            <div className='h-9'>
                                <img src="/about/about-tec-icon6.png" alt="Sobre a Embed" className="h-full text-start" />                    
                            </div>
                            <div>
                                <p className='text-[14px] font-medium text-start mt-3'>
                                    Somos loucos por pontos de venda!
                                </p>    
                            </div>
                        </div>
                        <div className='w-[160px]'>
                            <div className='h-9'>
                                <img src="/about/about-tec-icon5.png" alt="Sobre a Embed" className="h-full text-start" />
                            </div>
                            <div>
                                <p className='text-[14px] font-medium  text-start mt-3'>
                                    Time de profissionais e especialistas disponível
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>

            <section className="makeEmbed">
                <div className='flex flex-col w-full justify-center items-center px-20 space-y-6'>
                <img src="/community-green-icon.png" alt="Sobre a Embed" className="h-auto w-28" />
                <h4>Fazer parte da nossa comunidade leva apenas alguns<br/>
                    minutos, <span className='font-bold'>preencha o formulário clicando abaixo:</span></h4>
                    <div><button className='rounded-md' onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSf5VYQzkPWJTbgqIA7Cg787U_5kW4lM5yg5Jfur5f1FLT9n0g/viewform', '_blank')}>Quero ser Embed!</button></div>
                </div>
            </section>

            {/* Nova Seção: Nosso Impacto */}
            {/* Nova Seção com Opções de Contato e Comunidade */}

        </div>
    );
};

export default About;
