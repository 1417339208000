import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Community from './pages/Community';
import Tef from './pages/Tef';
import Xml from './pages/Xml';
import Pos from './pages/Pos';
import Pix from './pages/pix';
import Payment from './pages/payment';
import Iaas from './pages/iaas';
import SubWhitelabel from './pages/SubWhitelabel';
import ServicoFinanceiro from './pages/ServicoFinanceiro';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


const App = () => {
    const ScrollToTop = () => {
        const { pathname } = useLocation();
    
        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);
    
        return null;
    };
    return (
        <div className="App">
            <Router>
            <ScrollToTop />
                <Navbar />
                <div className="content">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/comunidade" element={<Community />} />
                        <Route path="/tef" element={<Tef />} />
                        <Route path="/xml" element={<Xml />} />
                        <Route path="/pos" element={<Pos />} />
                        <Route path="/pix" element={<Pix />} />
                        <Route path="/iaas" element={<Iaas />} />
                        <Route path="/antecipacao-recebiveis" element={<Payment />} />
                        <Route path="/subadquirencia-whitelabel" element={<SubWhitelabel />} />
                        <Route path="/servico-financeiro" element={<ServicoFinanceiro />} />
                    </Routes>
                </div>
                <Footer />
            </Router>
        </div>
    );
};

export default App;
